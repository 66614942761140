import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from "reusecore/Layout";
import VintageBox from "reusecore/VintageBox";
import Button from "reusecore/Button";
import CheckBox from "reusecore/Checkbox";
import TextBox from "reusecore/Textbox";
import MultiToggle from "react-multi-toggle";
import QuoteWrapper from "./quote.style";
import LoadingOverlay from 'react-loading-overlay';

const planOptions = [
    {
        displayName: 'Starter',
        value: 1
    },
    {
        displayName: 'Enthusiast',
        value: 2
    },
    {
        displayName: 'Enterprise',
        value: 3
    },
];

const Quote = () => {

    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [noOfUsers, setNoOfUsers] = useState(null);

    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [errorShow, setErrorShow] = useState(false);
    const [inquirySent, setInquirySent] = useState(false);

    const [plan, setPlan] = useState(2);

    const [notification, setNotification] = useState(false);
    const [authenticator, setAuthenticator] = useState(false);
    const [approvalReq, setApprovalReq] = useState(false);
    const [todo, setTodo] = useState(false);
    const [expense, setExpense] = useState(false);
    const [timesheet, setTimesheet] = useState(false);
    const [leave, setLeave] = useState(false);
    const [survey, setSurvey] = useState(false);
    const [contact, setContact] = useState(false);
    const [analytics, setAnalytics] = useState(false);

    useEffect(() => {
    }, []);

    useEffect(() => {

        setErrorMsg(null);
        setErrorShow(false);

        if (!name || name.trim().length <= 0) {
            setErrorMsg("Name is not valid");
            return;
        }

        if (!email || email.trim().length <= 0) {
            setErrorMsg("Email is not valid");
            return;
        }

        if (!phone || phone.trim().length <= 0) {
            setErrorMsg("Phone is not valid");
            return;
        }

        if (!noOfUsers || noOfUsers.trim().length <= 0 || noOfUsers <= 0) {
            setErrorMsg("Number of user is not valid");
            return;
        }

        if (!(notification || authenticator || approvalReq || todo || expense || timesheet || leave || survey || contact || analytics)) {
            setErrorMsg("Please select at least one module");
            return;
        }

        setValid(true);
    }, [email, phone, name, noOfUsers, notification, authenticator, approvalReq, todo, expense, timesheet, leave, survey, contact, analytics]);

    // generate string of selected modules, comma separated
    const generateModulesText = () => {
        let modulesArr = [];
        if (notification) modulesArr.push("Notification");
        if (authenticator) modulesArr.push("Authenticator");
        if (approvalReq) modulesArr.push("Approval-Req");
        if (todo) modulesArr.push("ToDo");
        if (expense) modulesArr.push("Expense");
        if (timesheet) modulesArr.push("Timesheet");
        if (leave) modulesArr.push("Leave");
        if (survey) modulesArr.push("Survey");
        if (contact) modulesArr.push("Contact");
        if (analytics) modulesArr.push("Analytics");

        return modulesArr.join();
    }

    // send inquiry emails to both MyCorp sales and requester
    const send = async () => {

        // subaccount in mandrill
        const subAccount = 'MyCorp';

        // destination email address for mycorp inquiry
        const toMyCorp = 'sales@cyder.com.sg';

        // mandrill template for mycorp sales
        const templateMyCorp = 'mycorp-quote-no-obligation';

        // mandrill template for response to requester
        const templateResponse = 'mycorp-inquiry-response';

        setInquirySent(false);
        setLoading(true);
        setErrorMsg("");
        setErrorShow(false);

        const postBodyToMyCorp = {
            to: toMyCorp,
            subAccount,
            template: templateMyCorp,
            params: [
                {
                    name: 'name',
                    content: name,
                },
                {
                    name: 'email',
                    content: email,
                },
                {
                    name: 'phone',
                    content: phone,
                },
                {
                    name: 'plan',
                    content: planOptions.filter(e => e.value === plan)[0].displayName,
                },
                {
                    name: 'noOfUsers',
                    content: noOfUsers,
                },
                {
                    name: 'modules',
                    content: generateModulesText(),
                },
            ]
        };

        const postBodyToResponse = {
            to: email,
            subAccount,
            template: templateResponse,
            params: [
                {
                    name: 'name',
                    content: name,
                },
            ]
        };

        // send email to mycorp sales
        await axios.post('https://notify.api.mycorp.io/api/notify/mandrill', postBodyToMyCorp);

        // send response email to requster
        await axios.post('https://notify.api.mycorp.io/api/notify/mandrill', postBodyToResponse);

        setLoading(false);
        setInquirySent(true);
        
        window.scrollTo(0, 0);
    }


    return (
        <QuoteWrapper>
            <div className="single-post-wrapper">
                <Container>
                    {inquirySent ?
                        <Row>
                            <Col xs={12}>
                                <div className="comment-box-wrapper">
                                    <h2 className="block-title">No Obligation Quote</h2>
                                    <p>
                                        Thank you for your inquiry.
                                        </p>
                                </div>
                            </Col>
                        </Row>
                        :

                        <Row>
                            <Col xs={12}>
                                <div className="comment-box-wrapper">
                                    <h2 className="block-title">No Obligation Quote</h2>
                                    <p>
                                        Let us know know your interest on MyCorp
                                </p>
                                    <Row>
                                        <Col xs={12}>
                                            <TextBox
                                                value={name}
                                                onValueChange={setName}
                                                type="text"
                                                name="name"
                                                label="Name*"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <TextBox
                                                value={email}
                                                onValueChange={setEmail}
                                                type="email"
                                                name="email"
                                                label="Email*"
                                            />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <TextBox
                                                value={phone}
                                                onValueChange={setPhone}
                                                type="phone"
                                                label="Phone*"
                                                name="phone"
                                            />
                                        </Col>
                                    </Row>
                                    <MultiToggle
                                        options={planOptions}
                                        selectedOption={plan}
                                        onSelectOption={e => {
                                            setPlan(e);
                                        }}
                                        label="Choose plan*"
                                    />
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <TextBox
                                                value={noOfUsers}
                                                onValueChange={setNoOfUsers}
                                                type="number"
                                                name="noOfUsers"
                                                label="Number of user*"
                                            />
                                        </Col>
                                    </Row>
                                    <p className="simple-paragraph">Choose modules*</p>
                                    <Row>
                                        <Col xs={12} sm={4} md={3} lg={3}>
                                            <CheckBox
                                                checked={notification}
                                                name="notification"
                                                onCheckChanged={e => {
                                                    setNotification(!notification);
                                                }}
                                                label="Notification"
                                            />
                                        </Col>
                                        <Col xs={12} sm={4} md={3} lg={3}>
                                            <CheckBox
                                                checked={authenticator}
                                                name="authenticator"
                                                onCheckChanged={e => {
                                                    setAuthenticator(!authenticator);
                                                }}
                                                label="Authenticator"
                                            />
                                        </Col>
                                        <Col xs={12} sm={4} md={3} lg={3}>
                                            <CheckBox
                                                checked={approvalReq}
                                                name="approvalReq"
                                                onCheckChanged={e => {
                                                    setApprovalReq(!approvalReq);
                                                }}
                                                label="Approval-Req"
                                            />
                                        </Col>
                                        <Col xs={12} sm={4} md={3} lg={3}>
                                            <CheckBox
                                                checked={todo}
                                                name="todo"
                                                onCheckChanged={e => {
                                                    setTodo(!todo);
                                                }}
                                                label="ToDo"
                                            />
                                        </Col>
                                        <Col xs={12} sm={4} md={3} lg={3}>
                                            <CheckBox
                                                checked={expense}
                                                name="expense"
                                                onCheckChanged={e => {
                                                    setExpense(!expense);
                                                }}
                                                label="Expense"
                                            />
                                        </Col>
                                        <Col xs={12} sm={4} md={3} lg={3}>
                                            <CheckBox
                                                checked={timesheet}
                                                name="timesheet"
                                                onCheckChanged={e => {
                                                    setTimesheet(!timesheet);
                                                }}
                                                label="Timesheet"
                                            />
                                        </Col>
                                        <Col xs={12} sm={4} md={3} lg={3}>
                                            <CheckBox
                                                checked={leave}
                                                name="leave"
                                                onCheckChanged={e => {
                                                    setLeave(!leave);
                                                }}
                                                label="Leave"
                                            />
                                        </Col>
                                        <Col xs={12} sm={4} md={3} lg={3}>
                                            <CheckBox
                                                checked={survey}
                                                name="survey"
                                                onCheckChanged={e => {
                                                    setSurvey(!survey);
                                                }}
                                                label="Survey"
                                            />
                                        </Col>
                                        <Col xs={12} sm={4} md={3} lg={3}>
                                            <CheckBox
                                                checked={contact}
                                                name="contact"
                                                onCheckChanged={e => {
                                                    setContact(!contact);
                                                }}
                                                label="Contact"
                                            />
                                        </Col>
                                        <Col xs={12} sm={4} md={3} lg={3}>
                                            <CheckBox
                                                checked={analytics}
                                                name="analytics"
                                                onCheckChanged={e => {
                                                    setAnalytics(!analytics);
                                                }}
                                                label="Analytics"
                                            />
                                        </Col>
                                    </Row>
                                    <br />
                                    {errorShow &&
                                        <p className="error-message">
                                            {errorMsg}
                                        </p>
                                    }
                                    <VintageBox
                                        right={true}
                                        vintageTwo={true}
                                        position="relative"
                                    >
                                        <Button onClick={e => {
                                            if (valid) {
                                                send();
                                            } else {
                                                setErrorShow(true);
                                            }
                                        }}>Request Quote</Button>
                                    </VintageBox>
                                    {/*                              
                                <LoadingOverlay
                                    active={loading}
                                    spinner
                                    text='Working on it ...'
                                /> */}
                                </div>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>

        </QuoteWrapper >
    );
};

export default Quote;
